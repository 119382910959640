import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';

const privacyPolicy = props => {
  return (
    <Layout>
      <SEO title="Pravila privatnosti - Monolit" noIndexNoFollow={true} />

      <div className="container padding-10-1-1">
        <h1>Pravila privatnosti</h1>

        <p>
          Monolit d.o.o. posvećuje veliku pozornost zaštiti osobnih podataka
          naših korisnika. Osobne podatke korisnika nikad ne skupljamo niti
          obrađujemo, osim ako ih korisnici nisu eksplicitno učinili dostupnima,
          na primjer prilikom prijave na naše newslettere ili kontakt forme.
          Obrada osobnih podataka temelji se na jednoj od zakonitih osnova
          obrade.
        </p>

        <p>
          Osobnim podacima smatraju se podaci kojima se korisnici mogu
          identificirati, poput imena, spola, adrese, e-maila ili poštanske
          adrese i drugog. Monolit d.o.o. ne prikuplja Vaše osobne podatke osim
          u slučaju kada se ih eksplicitno učinili dostupnima, čime pristajete
          odnosno dajete privolu na njihovo korištenje u niže navedene svrhe
          (članak 6. stavak 1. Opće uredbe o zaštiti podataka).
        </p>

        <p>
          Monolit d.o.o. koristit će Vaše osobne podatke za potrebe tehničkog
          upravljanja internetskim stranicama, analize te dostave općih i
          individualiziranih ponuda, s ciljem da Vam se omogući pristup posebnim
          informacijama te za potrebe komunikacije.
        </p>

        <p>
          Obavještavamo Vas da je moguće da Vaše osobne podatke učinimo
          dostupnima našim partnerima, isključivo s ciljem ispunjenja svrhe za
          koju su navedeni podaci prikupljeni.
        </p>

        <p>
          Monolit d.o.o. koristi tehnologiju takozvanih internetskih cookiesa
          (hrvatski: kolačića). Tehnologiju cookiesa koristimo kako bismo mogli
          pružati našu online uslugu, analizirati korištenje sadržaja, nuditi
          oglašivačka rješenja, kao i za ostale funkcionalnosti koje ne bismo
          mogli pružati bez korištenja cookiesa.
        </p>

        <p>
          Detaljan popis cookiesa potražite{' '}
          <Link to="cookies-politika">ovdje</Link>. Podaci pohranjeni
          korištenjem cookiesa na monolit.hr internetskim stranicama ne sadrže
          osobne detalje iz kojih bi se mogao utvrditi identitet korisnika.
          Suglasnost za cookiese na našim izdanjima možete povući kada god
          želite. Cookiese možete obrisati ili blokirati kroz svoj internetski
          preglednik ili koristeći specijalizirane softvere za to.
        </p>

        <p>
          Napominjemo kako onemogućavanje svih cookiesa može uzrokovati probleme
          u funkcioniranju većine stranica, pa tako i izdanja Monolit
          internetske stranice.
        </p>

        <p>
          Napominjemo kako se neki podaci, poput vrste preglednika koji
          koristite, broja posjeta, prosječnog vremena provedenog na stranicama,
          pregledavani sadržaj i slični obrađuju automatski prilikom pristupa
          monolit.hr internetskim stranicama. Navedeni podaci se koriste u svrhu
          unaprijeđenja funkcionalnosti naših stranica.
        </p>

        <p>
          Napominjemo kako korisnik ima pravo da se na njega ne odnosi odluka
          koja se temelji isključivo na automatiziranoj obradi, osim ako je
          takva odluka potrebna za sklapanje ili izvršenje ugovora između
          korisnika i Monolit d.o.o. dopuštena hrvatskim pravom ili pravom Unije
          ili temeljena na izričitoj privoli korisnika.
        </p>

        <p>
          Ukoliko ste dali izričitu privolu za primanje newslettera, možemo Vam
          poslati elektroničke poruke s marketinškim sadržajem. Podatke koje ste
          nam dostavili kako biste se pretplatili na newsletter koristit će se
          samo za slanje newslettera. Ukoliko želite odustati od pretplate na
          Newsletter, to možete učiniti u bilo kojem trenutku putem opcije
          otkazivanja pretplate koja se nalazi u newsletteru.
        </p>

        <p>
          Obrada podataka za primitak elektroničkih poruka nužna je za potrebe
          naših legitimnih interesa. Stoga niste obvezni dostaviti te podataka
          i/ili privolu na primitak biltena. Pravna osnova za primanje
          newslettera je Vaša privola (članak 6. stavak 1. Opće uredbe o zaštiti
          podataka). U slučaju da niste dali privolu newsletter vam neće biti
          poslan.
        </p>

        <p>
          Korisnik odlučuje koje podatke o sebi čini dostupnim našim izdanjima,
          odnosno monolit.hr internetskoj stranici. U slučaju promjene bilo
          kojeg Vašeg osobnog podatka molimo Vas da o promjenama obavijestite na
          e-mail: <a href="mailto:info@monolit.hr">info@monolit.hr</a> kako
          bismo ispravili ili ažurirali Vaše osobne podatke.
        </p>

        <p>
          Ovim putem Vas obavještavamo kako imate pravo povući danu privolu u
          bilo kojem trenutku u cijelosti ili djelomično, na način da se
          obavijesti na e-mail adresu:{' '}
          <a href="mailto:info@monolit.hr">info@monolit.hr</a> u pisanom ili
          elektroničkom obliku ili usmenim putem.
        </p>

        <p>
          Nadalje, da po primitku izjave o povlačenju privole, pisanim putem
          ćemo potvrditi primitak, a osobni podaci koji su obuhvaćeni izjavom o
          povlačenju se više neće obrađivati počevši od datuma povlačenja
          privole. Napominjemo kako sve obrade i/ili prijenosi učinjeni do
          datuma povlačenja privole i dalje će ostati pravno valjani.
        </p>

        <p>
          Osim prava na povlačenje privole korisnici, u skladu s važećim
          propisima o zaštiti podataka, imaju pravo na pristup i potvrdu Monolit
          internetske stranice d.o.o. u vezi s osobnim podacima, pravo na
          ispravak, pravo brisanja (“pravo na zaborav”), pravo na ograničenje
          obrade, pravo na prenosivost podataka te pravo na prigovor. Također,
          korisnici imaju pravo podnijeti pritužbu nadzornom tijelu za zaštitu
          podataka.
        </p>

        <p>
          Monolit ulaže velike napore kako bi se osigurala sigurnost osobnih
          podataka te usklađenost s važećim propisima o zaštiti podataka poput
          Opće uredbe o zaštiti podataka, Zakona o provedbi opće uredbe o
          zaštiti podataka i drugo. Napominjemo kako su Vaši podaci savjesno
          zaštićeni od gubitka, uništenja, manipulacije, neovlaštenoga pristupa
          i neovlaštenog izdavanja.
        </p>

        <p>
          <strong>Voditelj obrade:</strong> <br />
          Monolit d.o.o. <br />
          <a href="mailto:info@monolit.hr">info@monolit.hr</a>
        </p>

        <p>
          <strong>Službenik za zaštitu podataka:</strong> <br />
          <a href="mailto:info@monolit.hr">info@monolit.hr</a>
        </p>
      </div>
    </Layout>
  );
};

export default privacyPolicy;
